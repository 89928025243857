'use client';
import { ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import CustomImage from '@/app/_components/custom-image/CustomImage';
import { ReactComponent as ArrowLeft } from '@/app/_icons/arrow-left-white.svg';
import { ReactComponent as ArrowBackwardCircleDark } from '@/app/_icons/ArrowBackwardCircleDark.svg';
import { ReactComponent as ArrowForwardCircleDark } from '@/app/_icons/ArrowForwardCircleDark.svg';
import { assetsUri } from '@/services/api.service';
import { getImageLink } from '@/services/link.service';
import { ImageTransformationKeys } from '@/types/files/files.types';
import { directusImageLoader } from '@/utils/loaders';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import Styles from './ImageGallery.module.scss';

const imageKeys: ImageTransformationKeys = {
  desktopKey: 'new-car-model-gallery-desktop',
  mobileKey: 'new-car-model-gallery-mobile',
};

const ImageGallery = ({ gallery }: { gallery: string[] }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleFullscreenChange = () => {
    if (
      !document.fullscreenElement &&
      !(document as any).webkitFullscreenElement
    ) {
      setThumbsSwiper(null);
    }
  };

  const handleToggle = () => {
    if (isMobile) {
      onOpen();
    } else {
      if (
        document.fullscreenElement ||
        (document as any).webkitFullscreenElement
      ) {
        document.exitFullscreen?.() ||
          (document as any).webkitExitFullscreen?.();
      } else {
        elementRef.current?.requestFullscreen?.() ||
          (elementRef.current as any).webkitRequestFullscreen?.();
      }
    }
  };

  const closeFullscreen = () => {
    if (isMobile) {
      onClose();
    } else {
      document.exitFullscreen?.() || (document as any).webkitExitFullscreen?.();
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

    const toggleButton = toggleRef.current;

    toggleButton?.addEventListener('click', handleToggle);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange,
      );
      toggleButton?.removeEventListener('click', handleToggle);
    };
  }, [isMobile]);

  const FullScreenContent = () => (
    <Box className={Styles.fullScreenModalBody}>
      <Button
        role="button"
        aria-label="Close Fullscreen"
        className={Styles.fullScreenModalCloseBtn}
        onClick={closeFullscreen}
        padding={1}
        position={'absolute'}
      >
        <CloseIcon />
      </Button>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        navigation={{
          nextEl: '.slider-next-modal',
          prevEl: '.slider-prev-modal',
        }}
        className="swiper-modal-slider"
        modules={[Navigation]}
        loop={true}
      >
        {gallery?.map((image: string, i: number) => (
          <SwiperSlide key={i}>
            <Box className="slider-image">
              <Image
                src={`${assetsUri}/${image}?key=image-1800-892`}
                className="fancyImage"
                alt={`Kia image gallery - ${i + 1}`}
                width={1800}
                height={892}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box className="galleryModalSwiperNavigation">
        <button className={'slider-prev-modal'} role="button" name="Previous">
          <ArrowLeft role="button" name="Previous" />
        </button>
        <button className={'slider-next-modal'} role="button" name="Next">
          <ArrowLeft role="button" name="Next" />
        </button>
      </Box>
    </Box>
  );

  return (
    <Box className={Styles.galleryWrapper}>
      <Flex
        paddingX={{ base: 6, xl: 0 }}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
      >
        <Button
          role="button"
          className={Styles.btnTextLinkBorderless}
          aria-label="Open Fullscreen"
          ref={toggleRef}
        >
          View More <ChevronRightIcon />
        </Button>

        {isMobile ? (
          <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent
              className={Styles.fullScreenModalContent}
              height={'100%'}
            >
              <FullScreenContent />
            </ModalContent>
          </Modal>
        ) : (
          <Box className={Styles.fullScreenModalGallery} ref={elementRef}>
            <FullScreenContent />
          </Box>
        )}
        <Box className="mobileNavigation">
          <Box className="gallerySwiperControl">
            <Box className="swiper-pagination">
              <span className="current-page">{currentPage}</span>
              <span className="separator">/</span>
              <span className="total-pages">{totalPages}</span>
            </Box>
            <Box className="gallerySwiperNavigation">
              <button className={'slider-prev'} role="button" name="Previous">
                <ArrowBackwardCircleDark role="button" name="Previous" />
              </button>
              <button className={'slider-next'} role="button" name="Next">
                <ArrowForwardCircleDark role="button" name="Next" />
              </button>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Box className="image-slider">
        <Grid
          templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(12, 1fr)' }}
          gap={4}
        >
          <GridItem width={{ lg: '245px' }} colSpan={{ base: 2, lg: 1 }}>
            <Box className="slider-col">
              <Box className="slider-thumbs">
                <Swiper
                  onSwiper={setThumbsSwiper}
                  direction="vertical"
                  spaceBetween={16}
                  slidesPerView={4}
                  className="swiper-thumb-slider"
                  breakpoints={{
                    0: {
                      direction: 'horizontal',
                      slidesPerView: 2.5,
                    },
                    576: {
                      direction: 'horizontal',
                      slidesPerView: 3,
                    },
                    992: {
                      direction: 'vertical',
                      slidesPerView: 4,
                    },
                  }}
                  modules={[Pagination, Navigation, Thumbs]}
                >
                  {gallery?.map((image: string, i: number) => (
                    <SwiperSlide key={i}>
                      <Box className="slider-image thumb">
                        <Image
                          src={`${assetsUri}/${image}?key=image-185-127`}
                          loader={directusImageLoader}
                          className="fancyImage"
                          alt={`Kia image gallery - ${i + 1}`}
                          width={185}
                          height={127}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
              <Box className="thumbNavigation">
                <button className={'slider-prev'} role="button" name="Previous">
                  <ArrowLeft role="button" name="Previous" />
                </button>
                <button className={'slider-next'} role="button" name="Next">
                  <ArrowLeft role="button" name="Next" />
                </button>
              </Box>
            </Box>
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 11 }}>
            <Box className="slider-images">
              <Swiper
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                direction="horizontal"
                slidesPerView={1}
                spaceBetween={10}
                navigation={{
                  nextEl: '.slider-next',
                  prevEl: '.slider-prev',
                }}
                breakpoints={{
                  0: {
                    direction: 'horizontal',
                  },
                  768: {
                    direction: 'horizontal',
                  },
                }}
                className="swiper-big-slider"
                modules={[Pagination, Navigation, Thumbs]}
                pagination={{
                  el: '.swiper-pagination',
                  type: 'fraction',
                  formatFractionCurrent: function (number) {
                    return number;
                  },
                }}
                onSlideChange={(swiper) => {
                  setCurrentPage(swiper.realIndex + 1);
                }}
                onSwiper={(swiper) => {
                  setTotalPages(swiper.slides.length);
                }}
              >
                {gallery?.map((image: string, i: number) => (
                  <SwiperSlide key={i}>
                    <Box className="slider-image">
                      <CustomImage
                        imageSrc={getImageLink(image) ?? ''}
                        className="fancyImage"
                        loading={i === 0 ? 'eager' : 'lazy'}
                        alt={`Kia image gallery - ${i + 1}`}
                        imageKeys={imageKeys}
                      />
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

export default ImageGallery;
