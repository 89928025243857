import { atom } from 'recoil';

import { VehicleExtended } from '@/types/details-car/details-car.types';
import { Vehicle } from '@/types/used-cars/used-cars.types';

export const vehicleInfoState = atom<VehicleExtended>({
  key: 'vehicleInfo',
  default: {
    sale_source: '',
    extendedDescription: '',
    warranty: { image: '', title: '', description: '' },
    sale_end_date: '',
    id: '',
    brand: '',
    mileage: '',
    monthlyRepayment: 0,
    price: '',
    nowPrice: '',
    title: '',
    model: '',
    special: {},
    bodyType: '',
    mainImage: '',
    transmission: '',
    drivetrain: '',
    fuelType: '',
    doors: '',
    location: '',
    images: [],
    contactNumber: '',
    description: '',
    year: '',
    vin: '',
    registrationDate: '',
    registrationNumber: '',
    imagesCount: 0,
    dealership_id: '',
    bodyColors: '',
    cylinders: '',
    litres_100km: '',
    range_id: '',
    disk_drive_id: '',
    type: '',
    bodyTypeId: -1,
    bodyColorId: -1,
    bodyDoorsId: -1,
    featuresId: [],
    mmcode: '',
    stocknr: '',
    brandId: 0,
    popularity: 0,
    is_konfidence: false,
    duoportaId: '',
    rangeId: 0,
    digitalTwinUrl: null,
  },
});

export const specialsVehiclesState = atom<Vehicle[]>({
  key: 'specialsVehicles',
  default: [],
});

export const similarVehiclesState = atom<Vehicle[]>({
  key: 'similarVehicles',
  default: [],
});
