'use client';
import { Box, Link, SimpleGrid } from '@chakra-ui/react';
import Image from 'next/image';

import { ReactComponent as ArroRightBlack } from '@/app/_icons/Ck_arrow_right_black24px.svg';
import { getImageLink } from '@/services/link.service';
import { SpecialDetails } from '@/services/SpecialsService.service';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './CTA.module.scss';

const CTA = ({
  call_to_actions,
}: {
  call_to_actions: SpecialDetails['call_to_actions'];
}) => {
  return (
    <div className={Styles.CTASectionWrapper}>
      <SimpleGrid columns={[2, 2, 2, 2]} className={Styles.CTASection}>
        {call_to_actions &&
          call_to_actions.length > 0 &&
          call_to_actions?.filter(Boolean)?.map((cta, i) => {
            return (
              <Box className={Styles.toolsCard} textAlign={'center'} key={i}>
                <Image
                  src={getImageLink(cta?.call_to_actions_id?.icon) || ''}
                  loader={directusImageLoader}
                  alt="cta"
                  className={Styles.toolIcon}
                  width={90}
                  height={90}
                />
                <Link
                  className={Styles.themeLink}
                  textTransform={'capitalize'}
                  href={cta?.call_to_actions_id?.url || '#'}
                >
                  <span>{cta?.call_to_actions_id?.text || ''}</span>
                  <ArroRightBlack className={Styles.menuIcon} />
                </Link>
              </Box>
            );
          })}
      </SimpleGrid>
    </div>
  );
};

export default CTA;
