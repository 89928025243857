import { Box, Button, Heading, Text } from '@chakra-ui/react';
import React from 'react';

import { ReactComponent as SentImage } from '@/app/_icons/sent-image.svg';

import Styles from './thank-you-message.module.scss';

type ThankYouMessageProps = {
  onDone?: () => void;
};

const ThankYouMessage = ({ onDone }: ThankYouMessageProps) => {
  return (
    <Box className={Styles.newsLetterSentMessageWrapper}>
      <Box className={Styles.newsLetterSentMessage} marginBottom={8}>
        <SentImage />
        <Box className={Styles.headingElement} marginTop={6} marginBottom={2}>
          <Heading as="h3" className={Styles.titleMd}>
            Successfully
            <br />
            Subscribed
          </Heading>
        </Box>

        <Text>
          Please keep an eye open for the latest Kia news, delivered straight to
          your inbox.
        </Text>
      </Box>
      <Button onClick={onDone} className={Styles.btn} type="submit">
        <span>Done</span>
      </Button>
    </Box>
  );
};

export default ThankYouMessage;
