import { produce } from 'immer';
import debounce from 'lodash/debounce';
import slugify from 'react-slugify';

import { FILTER_BY_DEALERSHIP } from '@/constants/dealership/dealership.constants';
import {
  CMS_CONTENT_REQUEST_FIELDS,
  FILTER_ACTIVE_VEHICLES,
  FILTER_BY_BODY_TYPE_ID,
  FILTER_BY_TYPE,
  VEHICLES_REQUEST_FIELDS,
} from '@/constants/used-cars/used-cars.constants';
import { FOR_SALE, KIA_GROUP, SOUTH_AFRICA } from '@/constants/vars.constants';
import { apiService, directusService } from '@/services/api.service';
import { mapVehicleResponse } from '@/services/details-car/details-car.service';
import {
  capitalizeFirstLetter,
  removeHyphen,
  removeUnderscore,
} from '@/services/link.service';
import { VehicleReqExtended } from '@/types/details-car/details-car.types';
import {
  BalloonTypes,
  ChildFilter,
  FiltersTypes,
  FinanceFilterState,
  ParentFilter,
  SearchFilter,
} from '@/types/filters/filters.types';
import { PopularBodyType, PopularMake } from '@/types/home/home.types';
import { CarSearchMetaReq, VehicleReq } from '@/types/request/request.types';
import {
  BrowseItemTypes,
  CarSearchMeta,
  CMSUsedCars,
  UserCarsFilterQuery,
  Vehicle,
} from '@/types/used-cars/used-cars.types';

export function isParentFilter(arg: SearchFilter): arg is ParentFilter {
  return arg?.type === FiltersTypes.parent;
}

export function isChildFilter(arg: SearchFilter): arg is ChildFilter {
  return arg?.type === FiltersTypes.child;
}

export function mapVehiclesResponse(vehicles: VehicleReqExtended[]): Vehicle[] {
  return vehicles.map(mapVehicleResponse as any);
}

export function getSelectedParentChildFilters(options: ParentFilter[]) {
  const data = options.reduce((acc: SearchFilter[], parent) => {
    if (parent.children?.some((car) => car.isChecked)) {
      const mutedParent = {
        ...parent,
        children: parent.children.filter((child) => child.isChecked),
      };

      return [...acc, mutedParent];
    } else if (parent.isChecked && parent.children?.length === 0) {
      return [...acc, parent];
    }

    return [...acc];
  }, []);

  return data ? data : [];
}

export function checkEveryOptionChecked(options: ParentFilter[]) {
  return options.every((option) => option.isChecked);
}

export async function getVehiclesWithFilters(
  filtersQuery: UserCarsFilterQuery,
  metaQuery: CarSearchMeta,
  dealership_id?: string | null,
  type?: string | null,
  bodyTypeId?: number | null,
): Promise<{ vehicles: Vehicle[]; meta: CarSearchMetaReq }> {
  const offset = (metaQuery.currentPage - 1) * metaQuery.pageSize || 0;
  const getFilterQuery = (filtersQuery: any) => {
    if (!filtersQuery?.sales_price_including_vat?._between) return filtersQuery;

    const { sales_price_including_vat, ...newFiltersQuery } = filtersQuery;

    newFiltersQuery.display_price = sales_price_including_vat;
    return newFiltersQuery;
  };

  const { data, meta }: any = await directusService.get<VehicleReq[]>(
    '/items/vehicles',
    {
      params: {
        fields: VEHICLES_REQUEST_FIELDS.join(','),
        limit: metaQuery.pageSize,
        offset,
        sort: `sort,${metaQuery?.sort?.value}`,
        filter: {
          ...getFilterQuery(filtersQuery),
          ...FILTER_ACTIVE_VEHICLES,
          ...FILTER_BY_TYPE(type),
          ...FILTER_BY_DEALERSHIP(dealership_id),
          ...FILTER_BY_BODY_TYPE_ID(bodyTypeId),
        },
        meta: 'filter_count',
      },
    },
  );

  const vehiclesData: VehicleReqExtended[] = data;

  const vehicles = vehiclesData.map((vehicle) =>
    mapVehicleResponse(vehicle, vehicle?.images, null),
  );

  return { vehicles, meta };
}

export const getFinanceCalculation = async (
  financeFilter: FinanceFilterState,
  set: (param: any) => any,
  balloonOption: BalloonTypes,
) => {
  try {
    // const {
    //   monthlyPayment,
    //   totalRepayment,
    //   fees,
    //   registration_fee
    // }: FinanceCalculationReq = await oldApiService.get(
    //   '/vehicle-loan-calculation',
    //   {
    //     params: {
    //       carPrice: (financeFilter.price > financeFilter.deposit) ? (financeFilter.price - financeFilter.deposit) : 0,
    //       interestRate: financeFilter.interest,
    //       months: financeFilter.paymentTerm,
    //       carLoanDate: 'xxx',
    //       balloonAmount: financeFilter.balloon,
    //       isBalloonPercentage: balloonOption === BalloonTypes.percentage,
    //       // deposit: financeFilter.deposit
    //     },
    //   }
    // );

    const calculationResults: {
      monthlyPayment?: number;
      totalRepayment?: number;
      fees?: number;
      registration_fee?: number;
    } = await apiService.get('/vehicle-loan-calculation', {
      params: {
        carPrice: financeFilter.price,
        interestRate: financeFilter.interest,
        months: financeFilter.paymentTerm,
        carLoanDate: 'xxx',
        balloonAmount: financeFilter.balloon,
        isBalloonPercentage: balloonOption === BalloonTypes.percentage,
        deposit: financeFilter.deposit,
      },
    });

    set({
      monthlyPayment: calculationResults?.monthlyPayment || 0,
      totalRepayment: calculationResults?.totalRepayment || 0,
      fees: calculationResults?.fees || 0,
      registration_fee: calculationResults?.registration_fee || 0,
    });
  } catch (error) {
    console.error(error);

    return {
      monthlyPayment: 0,
      totalRepayment: 0,
    };
  }
};

export const getFinanceCalculationDebounce = debounce(
  getFinanceCalculation,
  1000,
);

export const toggleCheckboxFilters = (
  filters: ParentFilter[],
  filterToUpdate: ParentFilter | ChildFilter,
) => {
  return produce(filters, (draftFilters) => {
    if (isParentFilter(filterToUpdate)) {
      const parent = draftFilters.find(
        (parent) => parent.id === filterToUpdate.id,
      );

      if (!parent) return;
      parent.isChecked = !parent.isChecked;

      parent.children = parent.children?.map((child) => ({
        ...child,
        isChecked: parent.isChecked,
      }));
    }

    if (isChildFilter(filterToUpdate)) {
      const parent = draftFilters.find(
        (parent) => parent.id === filterToUpdate.parentId,
      );

      if (parent) {
        const child = parent?.children?.find(
          (child) => child.id === filterToUpdate.id,
        );

        if (!child) return;

        child.isChecked = !child.isChecked;

        if (parent.children?.every((child) => child.isChecked)) {
          parent.isChecked = !parent.isChecked;
        } else {
          parent.isChecked = false;
        }
      }
    }
  });
};

export const mapBrowseItem = (data: any[]): BrowseItemTypes[] => {
  return data.map((item) => ({
    id: item.id,
    fullName: item.fullName,
    name: slugify(item.name),
    isDisabled: item?.isDisabled,
    image: item.image || item.logo,
  }));
};

export const mapBrowseItemByPopularity = <
  T extends PopularBodyType | PopularMake,
>(
  data: any[],
  popularityData: T[],
  associatedField: string,
): BrowseItemTypes[] => {
  const items = data
    .map((bodyType) => {
      const popularityBodyType = popularityData?.find(
        (bType) => (bType as any)[associatedField] === bodyType.id,
      );

      return {
        id: bodyType.id,
        fullName: bodyType.fullName,
        name: bodyType.name,
        isDisabled: !popularityBodyType,
        image: bodyType.image || bodyType.logo,
        popularity: +(popularityBodyType?.sum.popularity ?? 0),
      };
    })
    .sort((a, b) => b.popularity - a.popularity);

  return items;
};

export const mapPopularModelsByPopularMakes = (
  data: any[],
  popularityData: PopularMake[],
) => {
  const items = data
    .map((item) => {
      const popularityBodyType = popularityData?.find(
        (bType) => bType['brand_id'] === item.id,
      );

      return {
        ...item,
        popularity: +(popularityBodyType?.sum.popularity ?? 0),
      };
    })
    .sort((a, b) => b.popularity - a.popularity);

  return items;
};

export const mapLeadValues = (values: any) => {
  const referring_url = encodeURI(window.location?.href?.slice(0, 254));
  const enquiry_url = encodeURI(window.location?.href?.slice(0, 254));
  const user_agent = window.navigator?.userAgent?.slice(0, 254);

  if (values.vehicles) {
    return {
      ...values,
      services: values?.services?.join(', '),
      referring_url,
      enquiry_url,
      user_agent,
    };
  } else {
    return {
      ...values,
      referring_url,
      enquiry_url,
      user_agent,
    };
  }
};

export const getSeoTitlePrice = (activePrice: string) => {
  return `Used Cars ${activePrice} - Used Cars For Sale | Kia Retail Group`;
};

export const getSeoTitleTypeWise = (carType: string, brandName: string) => {
  const bName = removeHyphen(brandName);
  const type = carType == 'demo' ? 3 : 1;

  if (type === 3) {
    return `Kia ${capitalizeFirstLetter(
      bName,
    )} Demo Cars ${FOR_SALE} | ${SOUTH_AFRICA} ${KIA_GROUP}`;
  } else {
    return `Used ${capitalizeFirstLetter(
      bName,
    )} Cars ${FOR_SALE} | Used Cars ${KIA_GROUP}`;
  }
};
export const getSeoTitleProvince = (carType: string, province: string) => {
  const provinceName = removeHyphen(province);
  const type = carType == 'demo' ? 3 : 1;

  if (type === 3) {
    return `Demo Cars ${capitalizeFirstLetter(
      provinceName,
    )} - Demo Cars ${FOR_SALE} ${KIA_GROUP}`;
  } else {
    return `Used Cars ${capitalizeFirstLetter(
      provinceName,
    )} - Used Cars ${FOR_SALE} ${KIA_GROUP}`;
  }
};

export const getSeoTitleProvinceWithCity = (
  carType: string,
  province: string,
  city: string,
) => {
  const provinceName = removeHyphen(province);
  const cityName = removeHyphen(city);
  const type = carType == 'demo' ? 3 : 1;

  if (type === 3) {
    return `Kia Demo Cars ${FOR_SALE} ${capitalizeFirstLetter(
      cityName,
    )} | ${capitalizeFirstLetter(provinceName)} ${KIA_GROUP}`;
  } else {
    return `Kia Used Cars ${FOR_SALE} ${capitalizeFirstLetter(
      cityName,
    )} | ${capitalizeFirstLetter(provinceName)} ${KIA_GROUP}`;
  }
};
export const getSeoTitleWithModel = (
  carType: string,
  brandName: string,
  modelName: string,
) => {
  const brand = removeHyphen(removeUnderscore(brandName));
  const model = removeHyphen(removeUnderscore(modelName));
  const type = carType == 'demo' ? 3 : 1;

  if (type === 3) {
    return `Kia Demo Cars ${capitalizeFirstLetter(
      brand,
    )} ${capitalizeFirstLetter(model)} ${FOR_SALE} ${KIA_GROUP}`;
  } else {
    return `Kia Used Cars ${capitalizeFirstLetter(
      brand,
    )} ${capitalizeFirstLetter(model)} ${FOR_SALE} ${KIA_GROUP}`;
  }
};

export const getCMSContent = async () => {
  const { data }: { data: CMSUsedCars } = await directusService.get(
    '/items/cms_used_cars',
    {
      params: {
        fields: CMS_CONTENT_REQUEST_FIELDS,
      },
    },
  );

  return data;
};
