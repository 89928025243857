import { SortOption, VehicleStatus } from '@/types/used-cars/used-cars.types';

export const KIA_ID = 52;
export const PAGE_SIZE = 15;
export const VEHICLES_REQUEST_FIELDS = [
  'date_updated',
  'main_image',
  'id',
  'status',
  'title',
  'year',
  'price',
  'vin',
  'monthly_repayment',
  'mileage',
  'dealer_network.name',
  'dealership_id.id',
  'dealership_id.province.id',
  'dealership_id.province.name',
  'dealership_id.province.code',
  'dealership_id.name',
  'dealership_id.main_number',
  'dealership_id.city.id',
  'dealership_id.city.name',
  'dealership_id.city.code',
  'dealership_id.city.country.name',
  'dealership_id.cms_used_dealer_code',
  'dealership_id.cms_new_dealer_code',
  'dealership_id.cms_used_leads',
  'dealership_id.cms_new_leads',
  'dealership_id.cms_source_used_cars_konfidence',
  'dealership_id.cms_source_used_cars',
  'brand_id.name',
  'brand_id.id',
  'range_id.name',
  'range_id.id',
  'model.name',
  'spec_body_type.name',
  'spec_body_type.id',
  'spec_doors.name',
  'spec_doors.id',
  'spec_fuel_type.name',
  'spec_litres_100km.float_value',
  'spec_transmission.name',
  'spec_body_colors.id',
  'spec_body_colors.name',
  'spec_features.spec_features_id',
  'spec_drivetrain.value',
  'images.directus_files_id.id',
  'images.directus_files_id.sort_order',
  'range_id.name',
  'disk_drive_id',
  'type',
  'stock_number',
  'sales_price_including_vat',
  'display_price',
  'now_price',
  'popularity',
  'duoporta_id',
  'is_konfidence',
  'sale_end_date',
  'sale_source',
  'warranty.*',
];

export const SIMILAR_VEHICLE_REQUEST_FIELDS = [
  'date_updated',
  'main_image',
  'brand_id.name',
  'brand_id.id',
  'range_id.name',
  'range_id.id',
  'id',
  'status',
  'title',
  'year',
  'price',
  'monthly_repayment',
  'mileage',
  'dealership_id.id',
  'dealership_id.name',
  'dealership_id.main_number',
  'dealership_id.cms_parts_leads',
  'dealership_id.cms_service_leads',
  'dealership_id.cms_finance_leads',
  'dealership_id.cms_used_leads',
  'dealership_id.cms_new_leads',
  'dealership_id.cms_source_used_cars_konfidence',
  'dealership_id.cms_source_used_cars',
  'dealership_id.cms_source_finance',
  'dealership_id.cms_source_service',
  'dealership_id.cms_used_dealer_code',
  'spec_body_type.name',
  'spec_doors.name',
  'spec_fuel_type.name',
  'spec_transmission.name',
  'spec_drivetrain.value',
  'images.directus_files_id.id',
  'images.directus_files_id.sort_order',
  'type',
  'stock_number',
  'sales_price_including_vat',
  'display_price',
  'now_price',
  'popularity',
  'is_konfidence',
];

export const PROVINCE_REQUEST_FIELDS = [
  'name',
  'id',
  'code',
  'image',
  'dealerships.id',
  'dealerships.name',
  'dealerships.code',
  'dealerships.latitude',
  'dealerships.longitute',
  'latitude',
  'longitute',
];

export const CMS_CONTENT_REQUEST_FIELDS = [
  'header_description',
  'footer_call_to_action.*',
  'footer_call_to_action_image',
  'vehicle_detail_call_to_actions.*',
  'vehicle_detail_call_to_actions.call_to_actions_id.*',
];

export const sortOptions: SortOption[] = [
  {
    value: '-date_activated',
    label: 'Latest',
  },
  {
    value: 'display_price',
    label: 'price (Low to High)',
  },
  {
    value: '-display_price',
    label: 'price (High to Low)',
  },
  {
    value: 'mileage',
    label: 'Mileage (Low to High)',
  },
  {
    value: '-mileage',
    label: 'Mileage (High to Low)',
  },
  {
    value: 'year',
    label: 'Year (Oldest to Newest)',
  },
  {
    value: '-year',
    label: 'Year (Newest to Oldest)',
  },
];
export const VEHICLE_STATUS_MAP = {
  [VehicleStatus.active]: 'active',
};

export const FILTER_ACTIVE_VEHICLES = {
  status: { _eq: VEHICLE_STATUS_MAP[VehicleStatus.active] },
  is_hide_on_website: { _eq: false },
};

export const ONLY_KIA = {
  brand_id: {
    id: { _eq: KIA_ID },
  },
};

export const FILTER_BY_BODY_TYPE_ID = (type?: number | null) => {
  if (!type) return null;

  return {
    spec_body_type: {
      _eq: type,
    },
  };
};

export const FILTER_BY_TYPE = (type?: string | null) => {
  const stringType = String(type);

  switch (stringType) {
    case '4':
      return {
        type: {
          _eq: type,
        },
      };
    case '3':
      return {
        type: {
          _eq: type,
        },
        ...ONLY_KIA,
      };
    case '1':
      return {
        type: {
          _eq: type,
        },
      };
    default:
      return {
        type: {
          _in: availableUsedTypes,
        },
      };
  }
};

export const FILTER_BY_BRAND = (id?: number) => {
  if (!id) return null;

  return {
    brand_id: {
      _eq: id,
    },
  };
};

export const FILTER_BY_PROVINCE = (id?: number | string) => {
  if (!id) return null;

  return {
    province: {
      _eq: id,
    },
  };
};

export const FILTER_BY_STATUS = (status?: string) => {
  if (!status) return null;

  return {
    status: {
      _eq: status,
    },
  };
};

export const FILTER_BY_DUOPORTA_ID = (id?: string) => {
  if (!id) return null;

  return {
    duoporta_id: {
      _eq: id,
    },
  };
};

export const FILTER_BY_MMCODE = (mmCode?: string) => {
  if (!mmCode) return null;

  return {
    mm_code: {
      _eq: mmCode,
    },
  };
};

export const FILTER_BY_YEAR = (year?: string) => {
  if (!year) return null;

  return {
    year: {
      _eq: year,
    },
  };
};

export const vehicleSearchHitory = 'vehicleSearchHitory';
export const availableUsedTypes = ['1', '2', '3'];

export const FILTER_BY_SALE = (onSale: boolean) => {
  if (!onSale) return {};

  return {
    sale_end_date: {
      _gte: '$NOW',
    },
  };
};

export const USED_ALGOLIA_FILTER = `status:active AND (type:used OR type:konfidence OR type:demo)`;