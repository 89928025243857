import { Box, FormControl } from '@chakra-ui/react';
import { notification } from 'antd';
import { usePathname } from 'next/navigation';
import { parseCookies } from 'nookies';
import React, { useEffect, useState } from 'react';

import { ReactComponent as LocationIcon } from '@/app/_icons/CkMapIcon.svg';
import {
  COOKIE_NAME_DEALERSHIP_ID,
  ENQUIRY_FORM_FOR_NEW_CARS,
  ENQUIRY_FORM_FOR_PARTS,
  ENQUIRY_FORM_FOR_SERVICE,
} from '@/constants/dealership/dealership.constants';
import { useAfterSales } from '@/hooks/after-sales/after-sales.hook';
import { useDealersInfo, useGeoLocation } from '@/hooks/dealers/dealers.hook';
import { useEnquireForm } from '@/hooks/enquire-form/enquire-form.hook';
import { useRoutesConfig } from '@/hooks/routes-config';
import { getFirstDealer } from '@/services/dealers/dealers.service';
import {
  getCurrentLocation,
  sortByDistance,
} from '@/services/location.service';
import { DealerItem } from '@/types/dealers/dealers.types';

import Styles from './location-button.module.scss';

type LocationButtonProps = {
  availableDealershipIds?: string[];
};

export const LocationButtonNewCars: React.FC<LocationButtonProps> = ({
  availableDealershipIds,
}) => {
  const { setSelectedDealership } = useEnquireForm();
  const { dealersInfo, listOfDealersByLocation, setListOfDealersByLocation } =
    useDealersInfo();
  const { isOpenPartsEnquiry } = useAfterSales();
  const routers = useRoutesConfig();
  const pathname = usePathname() || '';

  const [shouldRender, setShouldRender] = useState<boolean | null>(null);

  useEffect(() => {
    const cookies = parseCookies();
    const kiaDealarshipId = cookies?.[COOKIE_NAME_DEALERSHIP_ID];

    setShouldRender(!kiaDealarshipId);
  }, []);
  const getCorrectDealers = (dealers: DealerItem[]) => {
    let filteredDealers = dealers;

    if (availableDealershipIds?.length) {
      filteredDealers = filteredDealers.filter((dealer) =>
        availableDealershipIds.includes(dealer.id),
      );
    }

    const newCarsUrl = pathname.indexOf(`${routers.newCars.path}/`);

    if (
      newCarsUrl !== -1 ||
      pathname === routers.newCars.path ||
      (newCarsUrl !== -1 && pathname.indexOf('?') !== -1)
    ) {
      filteredDealers = filteredDealers.filter((dealer) => {
        return dealer?.enquiry_form_services?.includes(
          ENQUIRY_FORM_FOR_NEW_CARS,
        );
      });
    }

    if (
      pathname === routers.afterSales.path ||
      pathname.indexOf(`${routers.afterSales.path}?`) !== -1
    ) {
      if (isOpenPartsEnquiry) {
        filteredDealers = filteredDealers.filter((dealer) => {
          return dealer?.enquiry_form_services?.includes(
            ENQUIRY_FORM_FOR_PARTS,
          );
        });
      } else {
        filteredDealers = filteredDealers.filter((dealer) => {
          return dealer?.enquiry_form_services?.includes(
            ENQUIRY_FORM_FOR_SERVICE,
          );
        });
      }
    }

    return filteredDealers;
  };

  const [nearestDealership, setNearestDealership] = useState(
    getFirstDealer(getCorrectDealers(listOfDealersByLocation)),
  );
  const [error, setError] = useState('');
  const { userLocation, setUserLocation } = useGeoLocation();

  const [, setLocationAddress] = useState('');

  useEffect(() => {
    if (shouldRender === true) {
      getCurrentLocation()
        .then(({ lat, lng }) => {
          if (lat && lng) {
            setUserLocation({ lat, lng });
          }

          if (!lat && !listOfDealersByLocation.length) {
            setListOfDealersByLocation(dealersInfo);
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [shouldRender, listOfDealersByLocation, dealersInfo]);

  useEffect(() => {
    if (shouldRender === true) {
      const sortedDealerships = sortByDistance(userLocation, dealersInfo);
      const correctDealers = getCorrectDealers(sortedDealerships);

      setNearestDealership(getFirstDealer(correctDealers));
    }
  }, [userLocation, dealersInfo, shouldRender]);

  const handleNearestDealership = () => {
    if (error) {
      notification.error({
        message: error,
      });
    } else if (nearestDealership) {
      setSelectedDealership(nearestDealership);
      setLocationAddress(
        nearestDealership.mainAddress +
          ' ' +
          nearestDealership.secondaryAddress,
      );
    }
  };

  if (!shouldRender) {
    return null;
  }

  return (
    <FormControl id="location">
      <Box
        className={Styles.locationButton}
        onClick={() => handleNearestDealership()}
      >
        Use My location
        <LocationIcon className={Styles.locationIcon} />
      </Box>
    </FormControl>
  );
};
