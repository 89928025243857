'use client';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { getImageProps } from 'next/image';
import { Autoplay, EffectFade, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { PriceFormat } from '@/app/_components/price-format/price-format';
import { useOpenFloatingCTA } from '@/hooks/floating-cta.hook';
import { getImageLink } from '@/services/link.service';
import { SpecialDetails } from '@/services/SpecialsService.service';
import { ImageTransformationKeys } from '@/types/files/files.types';
import { VehicleType } from '@/types/used-cars/used-cars.types';
import { directusImageLoader } from '@/utils/loaders';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import styles from './BannerSection.module.scss';

type Props = {
  specialDetails: SpecialDetails;
  imageKeys: ImageTransformationKeys;
};

const BannerSection = ({ specialDetails, imageKeys }: Props) => {
  const common = {
    alt: 'Hero',
    width: 390,
    height: 200,
    loader: directusImageLoader,
  };
  const openLeadForm = useOpenFloatingCTA();

  const handleClick = () => {
    openLeadForm('test-drive');
  };

  const image = getImageLink(
    specialDetails?.banner_image
      ? (specialDetails?.banner_image as string)
      : (specialDetails?.image as string),
  );
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    width: 383,
    src: image ? `${image}?key=${imageKeys.mobileKey}` : '',
  });

  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    width: 1024,
    height: 680,
    src: image ? `${image}?key=${imageKeys.desktopKey}` : '',
  });

  return (
    <>
      <Swiper
        effect={'fade'}
        autoplay={{
          delay: 4500,
        }}
        loop={true}
        navigation={false}
        modules={[EffectFade, Navigation, Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        className={styles.heroSwiper}
      >
        <SwiperSlide>
          <Box
            className={styles.heroSwiperWrap}
            display={'flex'}
            justifyContent={'center'}
            flexDirection={{ base: 'column', lg: 'row' }}
            paddingBottom={{ base: '56px', sm: '0' }}
          >
            <Container
              className={styles.heroBannerContentWrapper}
              maxW={'1280'}
              width={'100%'}
              alignContent={'center'}
              zIndex={1}
              paddingX={{ base: 6 }}
              paddingY={'56px'}
            >
              <Box
                className={styles.heroBannerContent}
                textAlign={{ base: 'center', md: 'left' }}
              >
                <Box className={styles.headingElement}>
                  <Heading
                    as="h1"
                    className={styles.titleXl}
                    color={'white'}
                    marginBottom={6}
                  >
                    {specialDetails?.title}
                  </Heading>
                  <Text color={'white'} marginBottom={2}>
                    {specialDetails?.sub_title}
                  </Text>
                  <Heading
                    as="h2"
                    className={styles.titleText}
                    color={'white'}
                    marginBottom={6}
                  >
                    {specialDetails?.monthly_repayment ? (
                      <>
                        <PriceFormat
                          prefix="R"
                          value={specialDetails?.monthly_repayment}
                        />
                        {' p/m'}
                      </>
                    ) : specialDetails?.sale_price_incl_vat ? (
                      <PriceFormat
                        prefix="R"
                        value={specialDetails?.sale_price_incl_vat}
                      />
                    ) : specialDetails?.cashback_amount ? (
                      <PriceFormat
                        prefix="R"
                        value={specialDetails?.cashback_amount}
                      />
                    ) : null}
                  </Heading>
                </Box>
                {specialDetails?.vehicle_type === VehicleType.New && (
                  <ButtonCustom
                    btnText="Book a Test Drive"
                    className="btnWhite"
                    moduleClass={styles.bannerCta}
                    onClick={handleClick}
                  />
                )}
              </Box>
            </Container>
            <picture className={styles.heroImage}>
              <source media="(max-width: 575px)" srcSet={mobile} />
              <source media="(min-width: 576px)" srcSet={desktop} />
              <img
                {...rest}
                alt="hero-banner-image"
                loading="eager"
                fetchPriority="high"
              />
            </picture>
          </Box>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default BannerSection;
