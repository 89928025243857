import { useRecoilState } from 'recoil';

import {
  isOpenBookServiceForm,
  isOpenPartsEnquiryForm,
} from '@/states/after-sales/forms.states';

export function useAfterSales() {
  const [isOpenBookService, setIsOpenBookService] = useRecoilState(
    isOpenBookServiceForm
  );
  const [isOpenPartsEnquiry, setIsOpenPartsEnquiry] = useRecoilState(
    isOpenPartsEnquiryForm
  );

  return {
    isOpenBookService,
    setIsOpenBookService,
    isOpenPartsEnquiry,
    setIsOpenPartsEnquiry,
  };
}
