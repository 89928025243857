import { parseCookies } from 'nookies';
import { useRecoilState } from 'recoil';

import { COOKIE_NAME_DEALERSHIP_ID } from '@/constants/dealership/dealership.constants';
import { dealershipInfoState } from '@/states/dealership/dealership.states';

export function useDealershipInfo() {
  const [dealershipInfo, setDealershipInfo] =
    useRecoilState(dealershipInfoState);

  return {
    dealershipInfo,
    setDealershipInfo,
  };
}

export function useDealershipCookie() {
  const cookies = parseCookies({ req: null as any });
  const kiaDealershipId = cookies?.[COOKIE_NAME_DEALERSHIP_ID];

  return {
    kiaDealershipId,
  };
}
