import { Vehicle } from '../used-cars/used-cars.types';

export enum FiltersTypes {
  brand,
  range,
  body,
  colour,
  doors,
  features,
  transmission,
  fuel,
  drivetrain,
  province,
  city,
  parent,
  child,
  price,
  year,
  mileage,
}

export interface SearchFilter {
  id: string;
  type?: FiltersTypes;
  name: string;
  isChecked?: boolean;
  isOpened?: boolean;
  fullName?: string;
  code?: string;
  vehicleCount?: number;
  logo?: string;
  alias?: string[] | [];
  image?: string;
  isDisabled?: boolean;
  latitude?: number | null;
  longitute?: number | null;
  children?: {
    id: string | number;
  }[];
  vehicles?: Vehicle[] | null | number[];
}

export interface CarFilter {
  id: number;
  type: FiltersTypes;
  name: string;
  dealer_name_body_type?: string;
  fullName: string;
  isSelected: boolean;
  image?: string;
  alias?: string[] | [];
  value?: string;
  isDisabled?: boolean;
  vehicles?: [] | null | number[];
}

export interface BodyType extends CarFilter {
  image: string;
}

export interface ColourType extends CarFilter {
  color: string;
}

export type FeatureFilter = CarFilter;

export interface FeatureFilters {
  comfort: FeatureFilter[];
  safetyAndTech: FeatureFilter[];
  other: FeatureFilter[];
}

export interface PriceFilter {
  min: number;
  max: number;
}

export interface YearsFilter {
  min: number;
  max: number;
}

export interface ConsumptionFilter {
  min: number;
  max: number;
}

export interface MileageFilter {
  min: number;
  max: number;
}

export interface ParentFilter extends SearchFilter {
  children?: ChildFilter[];
}

export interface ChildFilter extends SearchFilter {
  parentId?: string | number;
}

export interface SimpleFilter {
  fullName: string;
}

export interface SaleFilter {
  sale_end_date?: {
    _gte: string;
  };
}

export interface FinanceFilterState {
  deposit: number;
  balloon: number;
  interest: number;
  paymentTerm: number;
  price: number;
}

export enum FinanceParts {
  deposit = 'deposit',
  price = 'price',
  balloon = 'balloon',
  interest = 'interest',
}

export enum BalloonTypes {
  value,
  percentage,
}

export interface SpecCountType {
  specId: number;
  count: number;
}

export type BallonType = '%' | 'R';
