export const NEW_CARS_FIELDS = [
  'main_title',
  'id',
  'price_from',
  'vehicle_preview.id',
  'vehicle_preview.title',
  'body_type.title',
  'body_type.value',
  'image_no_background_left_angle',
  'specification_prices',
  'images_warranty',
  'images_warranty',
  'source',
  'hide_in_build_and_price',
];
export const NEW_CARS_FILTER = {
  status: { _eq: 'published' },
};

export const NEW_CARS_HIDDEN_BUILD_AND_PRICE = {
  hide_in_build_and_price: { _eq: false },
};
export const NEW_CARS_LIST_FIELDS = [
  'id',
  'details.*',
  'details.model_id.*',
  'vehicle_preview',
  'body_type.title',
  'body_type.value',
  'logo_for_brand',
  'main_title',
  'main_background',
  'price_from',
  'source',
  'youtube_video_link',
  'price_disclaimer',
  'intro_subheading',
  'intro_title',
  'intro_description',
  'intro_columns',

  'images_title',
  'images_banner',
  'images_fuel_consumption',
  'images_warranty',
  'images_emmission',
  'co2_emissions',
  'co2_emissions_heading',
  'images_small',

  'colour.id',
  'colour.cms_new_vehicles_colours_id.*',

  'hybrid_title',
  'hybrid_technology_top',
  'hybrid_banner_title',
  'hybrid_banner',
  'hybrid_technology_bottom',

  'features_columns.*',
  'features_columns.image_text_m2m_id.*',

  'link_for_button_small_image',
  'name_for_button_small_image',
  'title_for_small_image',
  'link_for_button_big_image',
  'is_big_image_link',
  'name_of_button_big_image',
  'title_for_big_image',
  'small_image',
  'big_image',
  'specification',
  'disc_drive_spec.*.*',
  'specification_prices',

  'gallery_interior',
  'gallery_exterior',

  'structured_data_faq',
  'url',
  'status',

  'peerview_item_id',
  'peerview_type',
];

export const NEW_CARS_MODEL_FIELDS = [
  'id',
  'vehicle_preview',
  'body_type.title',
  'body_type.value',
  'brochure',
  'main_title',
  'color',
  'colour.*',
  'price_from',
  'youtube_video_link',
  'images_title',
  'images_banner',
  'images_fuel_consumption',
  'images_warranty',
  'images_emmission',
  'co2_emissions',
  'co2_emissions_heading',
  'images_small',
  'image_no_background_left_angle',
  'image_no_background_side',
  'image_alt_tag',
  'colour.id',
  'colour.cms_new_vehicles_colours_id.*',
  'colour.cms_new_vehicles_colours_id.model_colour_images.model_colour_images_id',
  'colour.cms_new_vehicles_colours_id.model_colour_images.id',
  'colour.cms_new_vehicles_colours_id.model_colour_images.model_colour_images_id.id',
  'colour.cms_new_vehicles_colours_id.model_colour_images.model_colour_images_id.mm_code',
  'colour.cms_new_vehicles_colours_id.model_colour_images.model_colour_images_id.name',
  'colour.cms_new_vehicles_colours_id.model_colour_images.model_colour_images_id.image_no_background_side',
  'colour.cms_new_vehicles_colours_id.model_colour_images.model_colour_images_id.image_no_background_left_angle',
  'features_columns.*',
  'features_columns.image_text_m2m_id.*',
  'link_for_button_small_image',
  'name_for_button_small_image',
  'title_for_small_image',
  'link_for_button_big_image',
  'is_big_image_link',
  'name_of_button_big_image',
  'title_for_big_image',
  'small_image',
  'price_per_month_from',
  'big_image',
  'specification',
  'specification_prices',
  'gallery_interior',
  'gallery_exterior',
  'url',
  'status',
  'hide_in_build_and_price',
  'horizontal_features_title_1',
  'horizontal_features_subtitle_1',
  'horizontal_features_1',
  'horizontal_features_title_2',
  'horizontal_features_subtitle_2',
  'horizontal_features_2',
  'vertical_features_title_1',
  'vertical_features_subtitle_1',
  'vertical_features_1',
  'vertical_features_title_2',
  'vertical_features_subtitle_2',
  'vertical_features_2',
  'footer_call_to_actions.*',
  'footer_call_to_actions.call_to_actions_id.*',
  'footer_call_to_action_image',
  'footer_call_to_actions.call_to_actions_id.text',
  'footer_call_to_actions.url',
  'footer_call_to_actions.type',
  'footer_call_to_actions.heading',
  'footer_call_to_actions.subheading',
  'footer_call_to_actions.image',
  'footer_call_to_actions.icon',
  'footer_call_to_actions.call_to_actions.call_to_actions_id.*',
  'awards.awards_id.*.*',
  'call_to_actions.call_to_actions_id.*',
  'images_warranty',
  'range',
  'range.id',
  'range.name',
  'spec_variant_specs.*',
  'spec_variant_specs.mm_code.id',
  'spec_variant_specs.mm_code.mmcode',
  'spec_variant_specs.mm_code.name',
  'peerview_item_id',
  'peerview_type',
  'source',
  'source_bp',
];

export const NEW_CARS_FIELDS_FOR_OVERVIEW = [
  'id',
  'main_title',
  'main_background',
  'price_from',
  'price_per_month_from',
  'vehicle_preview.id',
  'vehicle_preview.title',
  'body_type.title',
  'body_type.value',
  'price_from',
  'images_fuel_consumption',
  'images_emmission',
  'co2_emissions',
  'co2_emissions_heading',
  'image_no_background_left_angle',
  'price_disclaimer',
  'hide_in_build_and_price',
];

export const SEO_NEW_KIA_FOR_SALE = {
  TITLE: 'New KIA - New KIA For Sale | Kia Retail Group',
};

export const duoportaSpecificationNames = {
  introDate: 'intro date',
  range: 'range',
  model: 'model',
  rangeModel: 'range model',
  manufacturerModelCode: 'manufacturer model code',
  doors: 'doors',
  loadVolumeCapacity: 'load volume / capacity',
  cylinderLayoutQuantityEngineDetailShort:
    'cylinder layout + quantity / engine + detail (short)',
  cylinders: 'cylinders',
  cylinderLayout: 'cylinder layout',
  fuelType: 'fuel type',
  cylinderLayoutQuantity: 'cylinder layout + quantity',
  enginePositionLocation: 'engine position/location',
  engineCapacityCc: 'engine capacity (cc)',
  engineSizeLitre: 'engine size (litre)',
  engineDetail: 'engine + detail',
  bodyShape: 'body shape',
  charger: 'charger',
  supercharger: 'supercharger ',
  turbocharger: 'turbocharger',
  hybrid: 'hybrid',
  powerMaximum: 'power maximum',
  powerMaximumTotal: 'power maximum (total)',
  powerMaximumDetail: 'power maximum (detail)',
  powerPeakRevs: 'power peak revs',
  torqueMaximum: 'torque maximum',
  torquePeakRevs: 'torque peak revs',
  torqueMaximumTotal: 'torque maximum (total)',
  torqueMaximumDetail: 'torque maximum (detail)',
  drivenWheels: 'driven wheels',
  drivenWheelsQuantity: 'driven wheels quantity',
  gearRatiosQuantity: 'gear ratios (quantity)',
  lowRange: 'low range',
  gearshift: 'gearshift',
  transmissionType: 'transmission type',
  transmissionName: 'transmission name',
  gearshiftPaddles: 'gearshift paddles',
  fuelConsumptionUrban: 'fuel consumption: urban',
  fuelConsumptionExtraUrban: 'fuel consumption: extra-urban',
  fuelConsumptionAverage: 'fuel consumption: average',
  co2EmissionsAverage: 'CO2 emissions: average',
  fuelRangeAverage: 'fuel range: average',
  limitedSlipDiff: 'limited-slip diff',
  diffLockFront: 'diff lock - front',
  diffLockCentre: 'diff lock - centre',
  diffLockRear: 'diff lock - rear',
  tractionControl: 'traction control',
  stabilityControl: 'stability control',
  hillDescentControlDownhillBrakeControl:
    'hill descent control / downhill brake control',
  driverAirbag: 'driver airbag',
  frontPassengerAirbag: 'front passenger airbag',
  driverKneeAirbag: 'driver knee airbag',
  passengerKneeAirbag: 'passenger knee airbag',
  frontSideAirbags: 'front side airbags',
  rearSideAirbags: 'rear side airbags',
  curtainAirbags: 'curtain airbags',
  airbagQuantity: 'airbag quantity',
  airbagQuantityInclOpt: 'airbag quantity incl opt',
  antiLockBrakingSystemAbs: 'anti-lock braking system (ABS)',
  electronicBrakeDistributionEbd: 'electronic brake distribution (EBD)',
  brakeAssistBasEba: 'brake assist (BAS/EBA)',
  modelGroup: 'model group',
  brandRangeModel: 'brand range model',
  status: 'status',
  limitedEdition: 'limited edition',
  specLevelTrimLineGrade: 'spec level / trim line / grade',
  duoportaRecordId: 'duoporta record ID',
  rangeIntroDateSouthAfrica: 'range intro date (South Africa)',
  endDate: 'end date',
  dateRange: 'date range',
  priceExclEmissionsTax: 'price excl emissions tax',
  emissionsTaxIncludedInPrice: 'emissions tax (included in price)',
  price: 'price',
  priceDate: 'price date',
  segment: 'segment',
  engineDetailShort: 'engine + detail (short)',
  acceleration0100KmH: 'acceleration 0-100km/h',
  maximumTopSpeed: 'maximum/top speed',
  ceramicDiscs: 'ceramic discs',
  electroMechanicalParkingBrake: 'electro-mechanical parking brake',
  alloyWheelsRims: 'alloy wheels/rims',
  frontTyres: 'front tyres',
  rearTyres: 'rear tyres',
  spareWheelSizeFull: 'spare-wheel size: full',
  spareWheelSizeSpaceSaver: 'spare-wheel size: space-saver',
  runFlatTyres: 'run-flat tyres',
  tyreCompressorPump: 'tyre compressor/pump',
  tyreRepairKit: 'tyre repair kit',
  tyrePressureSensorMonitorDeflationDetectionSystem:
    'tyre pressure sensor/monitor / deflation detection system',
  sportsSuspension: 'sports suspension',
  electronicallyAdjustableSuspension: 'electronically-adjustable suspension',
  airSuspension: 'air suspension',
  airConditioning: 'air conditioning',
  climateControlAutomaticAirConditioning:
    'climate control / automatic air conditioning',
  powerSteering: 'power steering',
  leatherSteeringWheelRim: 'leather steering wheel rim',
  steeringWheelHeating: 'steering wheel heating',
  electricSteeringColumnAdjustment: 'electric steering column adjustment',
  electricSteeringColumnAdjustmentMemory:
    'electric steering column adjustment: memory',
  rearWheelSteer: 'rear-wheel steer',
  multiFunctionSteeringWheelControls: 'multi-function steering wheel controls',
  laneDepartureWarning: 'lane departure warning',
  laneChangeBlindSpotWarningAssistMonitor:
    'lane change / blind spot warning/assist/monitor',
  digitalInstrumentS: 'digital instrument/s',
  attentionAssistRestAssistBreakAlertFatigueDetection:
    'attention assist / rest assist / break alert / fatigue detection',
  headUpDisplay: 'head-up display',
  nightVision: 'night vision',
  onBoardComputerMultiInformationDisplay:
    'on-board computer / multi-information display',
  controlsInterfaceScreen: 'controls interface screen',
  navigation: 'navigation',
  cruiseControl: 'cruise control',
  activeAdaptiveCruiseControl: 'active/adaptive cruise control',
  bluetoothConnectivity: 'Bluetooth connectivity',
  cdPlayer: 'CD player',
  dvdReader: 'DVD reader',
  rearAudioMediaEntertainmentSystem: 'rear audio/media entertainment system',
  auxInAuxiliaryInputJack: 'aux in (auxiliary input jack)',
  usbPort: 'USB port',
  centralLocking: 'central locking',
  remoteCentralLocking: 'remote central locking',
  selectiveUnlocking: 'selective unlocking',
  keylessAccessStartHandsFreeKey: 'keyless access+start / hands-free key',
  childProofSafetyLock: 'child-proof/safety lock',
  electricChildProofSafetyLockSwitch: 'electric child-proof/safety lock switch',
  softCloseDoors: 'soft-close doors',
  electricSlidingDoors: 'electric sliding doors',
  electricWindows: 'electric windows',
  slidingCabWindow: 'sliding cab window',
  rearScreenRollerSunblindSunscreenSunshade:
    'rear screen roller sunblind / sunscreen / sunshade',
  rearSideWindowBlindsSunscreensSunshade:
    'rear side window blinds / sunscreens / sunshade',
  rearPrivacyGlass: 'rear privacy glass',
  heatedRearScreenRearDemister: 'heated rear screen / rear demister',
  rearScreenWiper: 'rear screen wiper',
  intermittentWiper: 'intermittent wiper',
  rainSensorAutoWipers: 'rain sensor (auto wipers)',
  autodimInteriorMirror: 'autodim interior mirror',
  electricAdjustMirrors: 'electric-adjust mirrors',
  memoryForElectricAdjustMirrors: 'memory for electric-adjust mirrors',
  heatedExteriorMirrors: 'heated exterior mirrors',
  autodimExteriorMirrors: 'autodim exterior mirrors',
  electricFoldRetractableMirrors: 'electric-fold/retractable mirrors',
  mirrorHousingsFinish: 'mirror housings finish',
  sunroof: 'sunroof',
  panoramicRoof: 'panoramic roof',
  foldingRoof: 'folding roof',
  vinylUpholstery: 'vinyl upholstery',
  clothUpholstery: 'cloth upholstery',
  suedeClothUpholstery: 'suede-cloth upholstery',
  leatherUpholstery: 'leather upholstery',
  seatsQuantity: 'seats (quantity)',
  seatsOptQuantity: 'seats (+ opt quantity)',
  lumbarSupportAdjustment: 'lumbar support adjustment',
  electricSeatAdjustmentDriver: 'electric seat adjustment - driver',
  memoryForElectricSeatAdjustment: 'memory for electric seat adjustment',
  electricSeatAdjustmentFrontPassenger:
    'electric seat adjustment - front passenger',
  frontArmrestS: 'front armrest/s',
  rearSeatElectricAdjustment: 'rear seat electric adjustment ',
  splitRearSeat: 'split rear seat',
  rearSeatSkiHatchLoadThroughHatch: 'rear seat ski hatch (load-through hatch)',
  foldingRearSeat: 'folding rear seat',
  sportsSeats: 'sports seats',
  heatedSeatsFront: 'heated seats - front',
  ventilatedSeatsFront: 'ventilated seats - front',
  massagingSeatsFront: 'massaging seats - front',
  heatedSeatsRear: 'heated seats - rear',
  ventilatedSeatsRear: 'ventilated seats - rear',
  massagingSeatsRear: 'massaging seats - rear',
  isofixChildSeatMountings: 'ISOFIX child seat mountings',
  rearArmrestS: 'rear armrest/s',
  rollOverProtectionBarsRollBar: 'roll-over protection bars / roll bar',
  windDeflector: 'wind deflector',
  daytimeDrivingRunningLights: 'daytime driving/running lights',
  lightSensorAutoOnOffLights: 'light sensor (auto on-off lights)',
  xenonHeadlights: 'xenon headlights',
  ledHeadlights: 'LED headlights',
  headlightWasherCleaningSystem: 'headlight washer / cleaning system',
  headlightLevelRangeHeightAdjustment:
    'headlight level/range/height adjustment',
  directionalTurningHeadlights: 'directional / turning headlights',
  adaptiveHeadlightsVaryingLightDistribution:
    'adaptive headlights (varying light distribution)',
  highBeamAssist: 'high-beam assist',
  frontFogLampsLights: 'front fog lamps/lights',
  sideIndicatorsInMirrorHousing: 'side indicators in mirror housing',
  highLevel3RdBrakeLight: 'high-level (3rd) brake light',
  ledTaillights: 'LED taillights',
  rearFogLampSLightS: 'rear fog lamp/s / light/s',
  bootLightLoadCompartmentLight: 'boot light / load compartment light',
  nudgeBarBullBar: 'nudge bar / bull bar',
  parkDistanceControlPdcFront: 'park distance control (PDC) - front',
  parkDistanceControlPdcRear: 'park distance control (PDC) - rear',
  cameraForParkDistanceControl: 'camera for park distance control',
  surroundTopAreaViewCameras: 'surround / top / area view cameras',
  parkAssistSelfParking: 'park assist (self-parking)',
  electricTailgateBootlid: 'electric tailgate/bootlid',
  sideStepPlatesBarsRunningBoards: 'side step plates/bars / running boards',
  roofRails: 'roof rails',
  rearSpoiler: 'rear spoiler',
  towbarTrailerHitch: 'towbar / trailer hitch',
  length: 'length',
  widthExclMirrorsInclMirrors: 'width excl mirrors - incl mirrors',
  widthExclMirrorsMirrorsFolded: 'width excl mirrors / mirrors folded',
  widthInclMirrors: 'width incl mirrors',
  height: 'height',
  wheelbase: 'wheelbase',
  groundClearanceMinimumMaximum: 'ground clearance minimum-maximum',
  turningCircleWheelsBody: 'turning circle (wheels - body)',
  approachAngle: 'approach angle',
  gradientInclineAngle: 'gradient/incline angle',
  breakOverRampAngle: 'break-over/ramp angle',
  rollOverTiltAngleLateralInclinationSlope:
    'roll-over/tilt angle / lateral inclination / slope',
  departureAngle: 'departure angle',
  wadingFordingWaterCrossingDepth: 'wading/fording (water crossing) depth',
  loadAreaLength: 'load area length',
  loadAeraWidth: 'load aera width',
  loadAeraHeightDepth: 'load aera height/depth',
  loadVolumeMinimum: 'load volume: minimum',
  loadVolumeIntermediate: 'load volume: intermediate',
  loadVolumeMaximum: 'load volume: maximum',
  unladenTareKerbWeightMin: 'unladen/tare/kerb weight min',
  unladenTareKerbWeight: 'unladen/tare/kerb weight',
  loadCarryingCapacityPayload: 'load carrying capacity / payload',
  grossWeightGvm: 'gross weight (GVM)',
  towingCapacityUnbraked: 'towing capacity - unbraked',
  towingCapacityBraked: 'towing capacity - braked',
  grossCombinationMassCombinedWeightGcm:
    'gross combination mass/ combined weight (GCM)',
  fuelTankCapacityInclReserve: 'fuel tank capacity (incl reserve)',
  warrantyTimeYears: 'warranty time (years)',
  warrantyTimeMonths: 'warranty time (months)',
  warrantyDistance: 'warranty distance',
  serviceMaintenancePlan: 'service/maintenance plan',
  planTimeYears: 'plan time (years)',
  planDistance: 'plan distance',
  servicePlan: 'service plan',
  servicePlanTimeYears: 'service plan time (years)',
  servicePlanTimeMonths: 'service plan time (months)',
  servicePlanDistance: 'service plan distance',
  maintenancePlan: 'maintenance plan',
  maintenancePlanTimeYears: 'maintenance plan time (years)',
  maintenancePlanTimeMonths: 'maintenance plan time (months)',
  maintenancePlanDistance: 'maintenance plan distance',
  serviceInterval: 'service interval',
  serviceIntervalDistance: 'service interval distance',
  serviceIntervalTime: 'service interval time',
  mmCodeTransUnionAuto: 'MM code TransUnion Auto',
  mmCode: 'MM code',
};

export const STRUCTURED_DATA_FAQ_GENERATOR = {
  features: {
    question: (mainTitle: string) =>
      `What features does the KIA ${mainTitle} have?`,
    answer: (mainTitle: string, features: string[]) => `
    The <strong>KIA ${mainTitle}</strong> has:
    <ul>
      ${features.map((feature) => `<li>${feature}</li>`)}
    </ul>
  `,
  },
  colours: {
    question: (mainTitle: string) =>
      `What colours is the KIA ${mainTitle} available in?`,
    answer: (
      mainTitle: string,
      colours: string[],
    ) => `The <strong>KIA ${mainTitle}</strong> is available in:
    <ul>
      ${colours.map((colour) => `<li>${colour}</li>`)}
    </ul>
  `,
  },
  specifications: {
    question: (mainTitle: string) =>
      `What different specifications does the KIA ${mainTitle} come in?`,
    answer: (
      mainTitle: string,
      specifications: string[],
    ) => `The <strong>KIA ${mainTitle}</strong> has the following specifications:
     <ul>${specifications.map((spec) => `<li>${spec}</li>`)}
    </ul>`,
  },
};
