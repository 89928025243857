import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import {
  enquireFormGeneralState,
  enquireFormGeneralTextState,
  selectedDealershipState,
} from '@/states/enquire-form-general.state';
import {
  activeEnquireFormVehicleIdState,
  enquireOptionsSelected,
  enquireOptionsState,
  selectActiveEnquireFormVehicle,
} from '@/states/used-cars/enquire-form.state';

export function useEnquireForm() {
  const [activeEnquireFormVehicleId, setActiveEnquireFormVehicleId] =
    useRecoilState(activeEnquireFormVehicleIdState);
  const setEnquireOptions = useSetRecoilState(enquireOptionsState);
  const activeEnquireFormVehicle = useRecoilValue(
    selectActiveEnquireFormVehicle,
  );
  const selectedEnquireOptions = useRecoilValue(enquireOptionsSelected);
  const resetEnquireOptions = useResetRecoilState(enquireOptionsState);
  const [isEnquireFormGeneralVisible, setIsEnquireFormGeneralVisible] =
    useRecoilState(enquireFormGeneralState);
  const [selectedDealership, setSelectedDealership] = useRecoilState(
    selectedDealershipState,
  );
  const [enquireFormGeneralText, setEnquireFormGeneralText] = useRecoilState(
    enquireFormGeneralTextState,
  );

  return {
    activeEnquireFormVehicle,
    activeEnquireFormVehicleId,
    setActiveEnquireFormVehicleId,
    setEnquireOptions,
    selectedEnquireOptions,
    resetEnquireOptions,
    isEnquireFormGeneralVisible,
    setIsEnquireFormGeneralVisible,
    selectedDealership,
    setSelectedDealership,
    enquireFormGeneralText,
    setEnquireFormGeneralText,
  };
}
