import {
  CLOSE_DEALERS_QUANTITY_TO_SHOW,
  DEALERS_REQUEST_FIELDS,
  FILTER_PUBLISHED_DEALERS,
} from '@/constants/dealers/dealers.constants';
import { directusService } from '@/services/api.service';
import { DateService } from '@/services/date.service';
import { getImageLink } from '@/services/link.service';
import { formatPhoneNumber } from '@/services/string-format.service';

export function mapDealersResponse(dealers: any[]) {
  return (
    dealers
      // TODO: Remove filter method when dealers will be valid
      ?.filter(({ latitude }) => latitude !== null)
      ?.map(
        ({
          id,
          name,
          address_1,
          address_2,
          address_3,
          code,
          main_number,
          main_image,
          latitude,
          longitute,
          google_address,
          operating_hours,
          city,
          cms_new_dealer_code,
          cms_used_dealer_code,
          cms_new_leads,
          cms_used_leads,
          cms_car_finder_leads,
          cms_general_leads,
          cms_parts_leads,
          cms_service_leads,
          cms_source_used_cars,
          province,
          enquiry_form_services,
          awards,
          peerview_item_id,
          peerview_type,
          dealership_team,
          description,
          warranties,
          dealership_awards_title,
          pre_owned_call_to_action,
          call_to_actions,
          trade_in_call_to_action,
          section_order,
          header_call_to_actions,
        }) => ({
          id: id || null,
          name: name || null,
          mainAddress: address_1 || null,
          mainImage: getImageLink(main_image) || null,
          secondaryAddress: address_2 || null,
          landmark: address_3 || null,
          code: code || null,
          phoneNumber: formatPhoneNumber(main_number) || null,
          city: city || null,
          cms_new_dealer_code: cms_new_dealer_code || null,
          cms_used_dealer_code: cms_used_dealer_code || null,
          cms_new_leads: cms_new_leads || null,
          cms_used_leads: cms_used_leads || null,
          cms_car_finder_leads: cms_car_finder_leads || null,
          cms_general_leads: cms_general_leads || null,
          cms_parts_leads: cms_parts_leads || null,
          cms_service_leads: cms_service_leads || null,
          cms_source_used_cars: cms_source_used_cars || null,
          lat: +latitude || 1,
          lng: +longitute || 1,
          google_address: google_address || null,
          awards: awards || null,
          peerview: {
            itemId: peerview_item_id || null,
            type: peerview_type || null,
          },
          description: description || null,
          dealership_team: dealership_team || null,
          operatingHours:
            operating_hours
              ?.map((item: any) => ({
                id: item.operating_hours_id?.id || null,
                day_of_the_week: DateService.getDayOfWeek(item) || null,
                openTime: item.operating_hours_id?.opening_time || null,
                closeTime: item.operating_hours_id?.closing_time || null,
              }))
              ?.sort((a: any, b: any) => a.id - b.id) || null,
          province: province || null,
          enquiry_form_services: enquiry_form_services,
          warranties: warranties || null,
          dealership_awards_title: dealership_awards_title || '',
          pre_owned_call_to_action: pre_owned_call_to_action || null,
          call_to_actions: call_to_actions || null,
          trade_in_call_to_action: trade_in_call_to_action || null,
          section_order: section_order || null,
          header_call_to_actions: header_call_to_actions || null,
        }),
      )
  );
}

export const getDealerNames = async (): Promise<
  { name: string; id: string }[]
> => {
  const { data } = await directusService.get<{ name: string; id: string }>(
    '/items/dealerships',
    {
      params: {
        fields: ['name', 'id'].join(','),
        filter: FILTER_PUBLISHED_DEALERS,
      },
    },
  );

  return data;
};

export const getKiaDealerOptions = async () => {
  const { data } = await directusService.get<{ name: string; id: string }>(
    '/items/dealerships',
    {
      params: {
        fields: ['name', 'id'].join(','),
        filter: {
          ...FILTER_PUBLISHED_DEALERS,
        },
      },
    },
  );

  return data;
};

export const getDealers = async () => {
  const { data } = await directusService.get('/items/dealerships', {
    params: {
      fields: DEALERS_REQUEST_FIELDS.join(','),
      filter: FILTER_PUBLISHED_DEALERS,
      sort: 'name',
    },
  });
  const dealers = mapDealersResponse(data);

  return dealers;
};

export const getKiaIndependents = async () => {
  const { data: dealerNetworks } = await directusService.get<
    { name: string; id: number }[]
  >('/items/dealer_networks', {
    params: {
      fields: 'id, name',
    },
  });

  const kiaIndependentsId =
    dealerNetworks?.find((dealer: any) => dealer.name === 'Kia Independents')
      ?.id ?? null;

  const { data: kiaIndependents } = await directusService.get(
    `/items/dealer_networks/${kiaIndependentsId}`,
    {
      params: {
        fields: 'dealership_ids.dealerships_id.name',
      },
    },
  );

  const kiaIndependentNames = kiaIndependents?.dealership_ids?.map(
    (dealer: any) => dealer?.dealerships_id?.name,
  );

  return kiaIndependentNames ?? [];
};

export const getFirstDealers = (dealers: any[]) => {
  if (dealers.length > CLOSE_DEALERS_QUANTITY_TO_SHOW) {
    return dealers.slice(0, CLOSE_DEALERS_QUANTITY_TO_SHOW);
  }
  return dealers;
};

export const getFirstDealer = (dealers: any[]) => dealers[0];

export const getCollectionWithDistance = (
  collection: any[],
  distance: { rows: { elements: { distance: { value: number } }[] }[] },
) => {
  return collection
    .filter((item) => item.lng && item.lat)
    .map((item, index) => ({
      distance: distance?.rows
        ? +(
            distance.rows[0]?.elements[index]?.distance?.value || 0 / 1000
          ).toFixed(1)
        : false,
      ...item,
    }))
    .sort((a, b) => {
      if (isNaN(a.distance)) {
        return 1;
      }

      if (isNaN(b.distance)) {
        return -1;
      }

      return a.distance - b.distance;
    });
};

export const getLocationsWithDistance = (
  locations: any[],
  distance: { rows: { elements: { distance: { text: string } }[] }[] },
) => {
  return locations
    .map((item, index) => ({
      distance: distance?.rows
        ? +(distance.rows[0]?.elements[index]?.distance?.text || '').replace(
            /\D/g,
            '',
          )
        : false,
      ...item,
    }))
    .sort((a, b) => a.distance - b.distance);
};

export const getDealersByProvincies = (dealers: any[]) =>
  dealers?.reduce((acc, dealer) => {
    if (!acc[dealer.city?.province?.name || dealer.province?.name]) {
      return {
        ...acc,
        [dealer.city?.province?.name || dealer.province?.name]: [
          {
            id: dealer.id,
            name: dealer.name,
          },
        ],
      };
    }

    return {
      ...acc,
      [dealer.city?.province?.name || dealer.province?.name]: [
        ...acc[dealer.city?.province?.name || dealer.province?.name],
        {
          id: dealer.id,
          name: dealer.name,
        },
      ],
    };
  }, {});

export function mapDealersResponseForEnquiry(dealers: any[]) {
  return dealers?.map(
    ({
      id,
      name,
      address_1,
      address_2,
      main_number,
      main_image,
      latitude,
      longitute,
      operating_hours,
      city,
      cms_new_dealer_code,
      cms_used_dealer_code,
      cms_new_leads,
      cms_used_leads,
      cms_car_finder_leads,
      cms_general_leads,
      cms_parts_leads,
      cms_service_leads,
      cms_source_used_cars,
    }) => ({
      id: id || null,
      name: name || null,
      mainAddress: address_1 || null,
      mainImage: getImageLink(main_image) || null,
      secondaryAddress: address_2 || null,
      phoneNumber: formatPhoneNumber(main_number) || null,
      city: city || null,
      cms_new_dealer_code: cms_new_dealer_code || null,
      cms_used_dealer_code: cms_used_dealer_code || null,
      cms_new_leads: cms_new_leads || null,
      cms_used_leads: cms_used_leads || null,
      cms_car_finder_leads: cms_car_finder_leads || null,
      cms_general_leads: cms_general_leads || null,
      cms_parts_leads: cms_parts_leads || null,
      cms_service_leads: cms_service_leads || null,
      cms_source_used_cars: cms_source_used_cars || null,
      lat: +latitude || 1,
      lng: +longitute || 1,
      operatingHours:
        operating_hours
          ?.map((item: any) => ({
            id: item.operating_hours_id?.id || null,
            day_of_the_week: DateService.getDayOfWeek(item) || null,
            openTime: item.operating_hours_id?.opening_time || null,
            closeTime: item.operating_hours_id?.closing_time || null,
          }))
          ?.sort((a: any, b: any) => a.id - b.id) || null,
    }),
  );
}
