import { atom, selector } from 'recoil';

export const isOpenPartsEnquiryForm = atom<boolean>({
  key: 'isOpenPartsEnquiryForm',
  default: false,
});

export const isOpenBookServiceForm = atom<boolean>({
  key: 'isOpenBookServiceForm',
  default: false,
});
