'use client';

import pickBy from 'lodash/pickBy';
import { atom, selector } from 'recoil';

import { vehicleInfoState } from '@/states/details-car/vehicle-info.state';
import { vehiclesState } from '@/states/used-cars/vehicles.state';
import { EnquireOptions } from '@/types/enquire-form/enquire-form.types';
import { Vehicle } from '@/types/used-cars/used-cars.types';

export const activeEnquireFormVehicleIdState = atom<string | null>({
  key: 'activeEnquireFormVehicleId',
  default: null,
});

export const selectActiveEnquireFormVehicle = selector<Vehicle>({
  key: 'selectActiveEnquireFormVehicle',
  get: ({ get }) => {
    const activeEnquireFormVehicleId = get(activeEnquireFormVehicleIdState);
    const vehicles = get(vehiclesState);
    const vehicle = get(vehicleInfoState);

    return (
      vehicles.find((vehicle) => vehicle.id === activeEnquireFormVehicleId) ||
      vehicle
    );
  },
  set: ({ set }, vehicleId) => {
    if (!vehicleId) {
      set(activeEnquireFormVehicleIdState, null);
    }
  },
});

export const enquireOptionsState = atom<EnquireOptions>({
  key: 'enquireOptionsState',
  default: {
    'Test Drive': false,
    Finance: false,
    'Trade in': false,
  },
});

export const enquireOptionsSelected = selector({
  key: 'enquireOptionsSelected',
  get: ({ get }) => {
    const enquireOptions = get(enquireOptionsState);

    return Object.keys(pickBy(enquireOptions));
  },
});
