import { atom } from 'recoil';

import { CarsDealership } from '@/types/dealership/dealership.types';

export const dealershipInfoState = atom<CarsDealership>({
  key: 'dealershipInfo',
  default: {
    id: '',
    main_image: '',
    name: '',
    main_number: '',
    location: '',
    google_address: '',
    operating_hours: [],
    enquiry_form_services: [],
    city: '',
  },
});
