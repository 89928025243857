'use client';

import { atom } from 'recoil';

import {
  PAGE_SIZE,
  sortOptions,
} from '@/constants/used-cars/used-cars.constants';
import {
  CarSearchMeta,
  Vehicle,
  ViewModes,
} from '@/types/used-cars/used-cars.types';

export const vehiclesState = atom<Vehicle[]>({
  key: 'vehicles',
  default: [],
});

export const vehiclesMetaState = atom<CarSearchMeta>({
  key: 'vehiclesMeta',
  default: {
    filterCount: 0,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    sort: sortOptions[0],
    isIndexSearch: false,
  },
});

export const vehiclesViewState = atom<ViewModes>({
  key: 'vehiclesView',
  default: ViewModes.tile,
});

export const vehiclesInitialState = atom<boolean>({
  key: 'vehiclesInitial',
  default: true,
});
