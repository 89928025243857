import {
  validEmail,
  validMobileNumber,
} from '@/constants/regexp/regexp.constants';

import { nameValidation } from './regexp/regexp.constants';

export const validateNameFiled = (_rule: any, value: any, callback: any) => {
  try {
    if (!value) {
      throw new Error('Please input your name!');
    }
    if (!value.trim()) {
      throw new Error('Name cannot be empty or whitespace!');
    }
    if (value.trim().length > 50 || value.trim().length < 2) {
      throw new Error(
        'Name must be longer than 2 and shorter than 50 characters!',
      );
    }
    if (!nameValidation.test(value.trim())) {
      throw new Error(
        'Name should contain only letters, spaces, apostrophes and dashes!',
      );
    }

    return Promise.resolve();
  } catch (error) {
    callback(error);
  }
};

export const validateFormNameFiled = (value: string | null) => {
  if (!value) {
    return 'Please input your Name!';
  }
  if (!value.trim()) {
    return 'Name cannot be empty or whitespace!';
  }
  if (value.trim().length > 50 || value.trim().length < 2) {
    return 'Name must be longer than 2 and shorter than 50 characters!';
  }
  if (!nameValidation.test(value.trim())) {
    return 'Name should contain only letters, spaces, apostrophes and dashes!';
  }
  return true;
};

export const validateFormEmailField = (value: string) => {
  if (!validEmail.test(value.trim())) {
    return 'Please enter a valid Email address!';
  }
  return true;
};

export const validatePhoneNumberField = (value: string) => {
  if (!validMobileNumber.test(value.trim())) {
    return 'Please enter a valid Phone number!';
  }
  return true;
};
