export const getUtmData = () => {
  try {
    const utm_data = sessionStorage.getItem('utm_data') || '';

    const data = JSON.parse(utm_data);

    return { ...data };
  } catch (error) {
    console.error(error);

    return {
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_content: '',
      utm_term: '',
      utm_dealership: '',
      utm_cta: '',
      fbclid: '',
      gclid: '',
    };
  }
};
