import { atom } from 'recoil';

import { DealerItem } from '@/types/dealers/dealers.types';

export const enquireFormGeneralState = atom<boolean>({
  key: 'enquireFormGeneral',
  default: false,
});

export const selectedDealershipState = atom<DealerItem | null>({
  key: 'selectedDealershipState',
  default: <DealerItem>{},
});

export const enquireFormGeneralTextState = atom<string>({
  key: 'enquireFormGeneralText',
  default: '',
});
