import {
  VehicleExtended,
  VehicleReqExtended,
} from '@/types/details-car/details-car.types';

export function mapVehicleResponse(
  vehicle: VehicleReqExtended,
  images: any[] = [],
  special = null,
): VehicleExtended {
  const allImages = ((images?.length > 0 ? images : vehicle.images) || [])
    .sort(
      (imageA, imageB) =>
        imageA.directus_files_id.sort_order -
        imageB.directus_files_id.sort_order,
    )
    .map((image) => ({
      directus_files_id: image.directus_files_id.id,
    }));

  const disclaimer = vehicle.disclaimer?.text?.replace(
    '{dealership}',
    vehicle.dealership_id.name,
  );

  const description = `${vehicle.vehicle_description}
  
${disclaimer}`;

  return {
    id: vehicle.id,
    brand: vehicle.brand_id?.name || '',
    mileage: vehicle.mileage,
    monthlyRepayment: Math.ceil(Number(vehicle.monthly_repayment)),
    price: Math.round(+vehicle.sales_price_including_vat) + '',
    nowPrice: Math.round(+vehicle.now_price) + '',
    title: vehicle.title,
    model: vehicle.model?.name || '',
    bodyType: vehicle.spec_body_type?.name || '',
    mainImage: vehicle.main_image
      ? vehicle.main_image
      : allImages.length > 0 && allImages[0]?.directus_files_id
        ? allImages[0]?.directus_files_id
        : null,
    transmission: vehicle.spec_transmission?.name || '',
    drivetrain: vehicle.spec_drivetrain?.value || '',
    fuelType: vehicle.spec_fuel_type?.name || '',
    doors: vehicle.spec_doors?.name || '',
    location: vehicle.dealership_id?.city?.name || '',
    images: allImages || null,
    special: special || null,
    contactNumber: vehicle.dealership_id?.main_number || '',
    imagesCount: vehicle.main_image
      ? (vehicle.images?.length || 0) + 1
      : vehicle.images?.length || 0,
    year: vehicle.year || '',
    vin: vehicle.vin || '',
    registrationDate: vehicle.registration_date || '',
    registrationNumber: vehicle.registration_number || '',
    description: description || '',
    dealership_id: vehicle?.dealership_id || null,
    range_id: vehicle.range_id?.name || '',
    bodyColors: vehicle.spec_body_colors?.name || '',
    cylinders: vehicle.spec_cylinders?.value || '',
    litres_100km: vehicle.spec_litres_100km?.float_value || '',
    disk_drive_id: vehicle.disk_drive_id || '',
    type: vehicle.type || '',
    bodyTypeId: vehicle.spec_body_type?.id || 0,
    bodyColorId: vehicle.spec_body_colors?.id || 0,
    bodyDoorsId: vehicle.spec_doors?.id || 0,
    featuresId: vehicle.spec_features
      ? vehicle.spec_features.map((feature) => feature.spec_features_id)
      : [],
    mmcode: vehicle.mm_code || '',
    stocknr: vehicle.stock_number || '',
    brandId: vehicle.brand_id?.id || 0,
    popularity: vehicle?.popularity || 0,
    is_konfidence: vehicle?.is_konfidence || false,
    duoportaId: vehicle?.duoporta_id || '',
    rangeId: vehicle?.range_id?.id || 0,
    sale_end_date: vehicle?.sale_end_date || '',
    sale_source: vehicle?.sale_source || '',
    extendedDescription: vehicle?.extended_description || '',
    digitalTwinUrl: vehicle?.digital_twin_url || null,
    warranty: (vehicle as any)?.warranty || null,
  };
}

export const removeFirstElement = (images: any[]) => {
  images?.shift();

  return images;
};
