import { mapLeadValues } from '@/services/used-cars/used-cars.service';

import { directusService, limiter } from './api.service';

export const createLead = async (values: any) => {
  const leadValues = mapLeadValues(values);

  await directusService.post('/items/leads', leadValues);
};

export const getNewDemoVehicleNames = limiter.wrap(
  async (): Promise<string[]> => {
    const { data } = await directusService.get('/items/cms_new_cars', {
      params: {
        fields: 'main_title',
      },
    });

    return [
      ...data
        .map((vehicle: any) => vehicle.main_title)
        .sort((a: any[], b: any[]) => b.length - a.length),
    ];
  },
);
