import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import cn from 'classnames';
import Image from 'next/image';
import { useRef, useState } from 'react';
import { Autoplay, EffectFade, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import CustomImage from '@/app/_components/custom-image/CustomImage';
import LinkCustom from '@/app/_components/link/LinkCustom';
import { PriceFormat } from '@/app/_components/price-format/price-format';
import PauseIconDisable from '@/app/_icons/pause-gray.svg';
import PauseIcon from '@/app/_icons/pause.svg';
import PlayIconDisable from '@/app/_icons/play-gray.svg';
import PlayIcon from '@/app/_icons/play.svg';
import { useFloatingCTA } from '@/hooks/floating-cta.hook';
import { getImageLink } from '@/services/link.service';
import { SpecialDetails } from '@/services/SpecialsService.service';
import { BannerCarousel } from '@/types/banners/banners.types';
import { ImageTransformationKeys } from '@/types/files/files.types';
import { NewCarData } from '@/types/new-cars/new-cars.types';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import Styles from './Banner.module.scss';

type BannerCommon = {
  priceFrom?: string | number;
  model?: string;
  align?: string;
  banner: BannerCarousel;
};

type NewCarBanner = {
  type: 'new_car';
  newVehicle: NewCarData;
  special?: never;
};

type SpecialBanner = {
  type: 'special';
  special: SpecialDetails;
  newVehicle?: never;
};

type BannerProps = (NewCarBanner | SpecialBanner) & BannerCommon;

const imageKey: ImageTransformationKeys = {
  mobileKey: 'image-560-325',
  desktopKey: 'image-1920-820',
};

const BannerSection = ({
  align = 'left',
  priceFrom,
  model,
  newVehicle,
  banner,
}: BannerProps) => {
  const video = getImageLink(banner?.video);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [playing, setPlaying] = useState(true);
  const { openLeadForm } = useFloatingCTA();

  const videoHandler = (control: string) => {
    if (control === 'play') {
      if (videoRef.current !== null) {
        videoRef.current.play();
      }

      setPlaying(true);
    } else if (control === 'pause') {
      if (videoRef.current !== null) {
        videoRef.current.pause();
      }
      setPlaying(false);
    }
  };

  return (
    <Box
      className={cn(Styles.bannerWrapper, {
        [Styles.topAlign as string]: align === 'top',
        [Styles.leftAlign as string]: align === 'left',
        [Styles.rightAlign as string]: align === 'right',
        [Styles.centerAlign as string]: align === 'centre_bottom',
      })}
    >
      <Box
        className={Styles.bannerSectionWrapper}
        paddingTop={8}
        paddingBottom={12}
        paddingX={6}
      >
        <Box className={Styles.headingElement} justifyContent={'center'}>
          <Heading
            as={!banner.video ? 'h1' : 'h2'}
            className={Styles.titleXl}
            color={'white'}
            marginBottom={6}
            textAlign={'center'}
            textTransform={'capitalize'}
          >
            {banner?.heading}
          </Heading>
        </Box>
        {priceFrom ? (
          <Stack gap={1} width={'max-content'} marginX={'auto'}>
            <Flex
              gap={{ base: '2' }}
              marginBottom={6}
              justifyContent={'center'}
            >
              <Text color={'white'} marginTop={'6px'}>
                From
              </Text>
              <Heading
                as="h2"
                className={Styles.titleMd}
                fontSize={{ base: '2xl', sm: '4xl' }}
                color={'white'}
              >
                {priceFrom !== undefined && (
                  <PriceFormat value={priceFrom} prefix={'R'} />
                )}
              </Heading>
              <Text color={'white'} alignSelf={'flex-end'} marginBottom={1}>
                Incl VAT
              </Text>
            </Flex>
          </Stack>
        ) : null}

        <Flex
          gap={4}
          width={'max-content'}
          marginX={'auto'}
          direction={'column'}
          justifyContent={'center'}
        >
          {banner?.show_book_test_drive && newVehicle && (
            <ButtonCustom
              btnText="Book a Test Drive"
              className="btnWhite"
              moduleClass={Styles.bannerCta}
              onClick={() =>
                openLeadForm('test-drive', 'TestDrive', {
                  type: 'new-car',
                  newCar: newVehicle,
                })
              }
            />
          )}
          {banner?.show_call_to_action &&
            banner?.call_to_action_url &&
            banner?.call_to_action_text && (
              <LinkCustom
                href={banner?.call_to_action_url}
                className="btnOutlineWhite"
                moduleClass={Styles.bannerCta}
              >
                {banner?.call_to_action_text}
              </LinkCustom>
            )}
          {banner?.show_call_to_action_2 &&
            banner?.call_to_action_url_2 &&
            banner?.call_to_action_text_2 && (
              <LinkCustom
                href={banner?.call_to_action_url_2 ?? '#'}
                className="btnWhite"
                moduleClass={Styles.bannerCta}
              >
                {banner?.call_to_action_text_2}
              </LinkCustom>
            )}
        </Flex>
      </Box>

      <Swiper
        effect={'fade'}
        autoplay={{
          delay: 4500,
        }}
        loop={true}
        navigation={false}
        modules={[EffectFade, Navigation, Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        className={Styles.heroSwiper}
      >
        <SwiperSlide>
          <Box className={Styles.slideDesktop}>
            <Box
              className={cn(
                Styles.heroSwiperWrap,
                video && Styles.heroSwiperWrapVideo,
              )}
            >
              {banner?.desktop_image && (
                <Box className={Styles.bannerImage}>
                  <CustomImage
                    imageKeys={imageKey}
                    imageSrc={getImageLink(banner?.desktop_image) ?? ''}
                    alt={`kia-${model}`}
                    width={1440}
                    height={817}
                    loading="eager"
                  />
                </Box>
              )}

              {video && (
                <Box className={Styles.videoPlayer}>
                  <video
                    playsInline
                    ref={videoRef}
                    className={Styles.videoPlayerFrame}
                    preload="auto"
                    autoPlay
                    muted
                    loop
                  >
                    <source src={video} type="video/mp4"></source>
                  </video>
                </Box>
              )}
              <Container
                className={Styles.heroBannerContentWrapper}
                maxW={'1280'}
                width={'100%'}
                zIndex={4}
              >
                <Box className={Styles.heroBannerContent}>
                  <Box className={Styles.headingElement}>
                    <Heading
                      as="h1"
                      className={Styles.titleXl}
                      color={'white'}
                      marginBottom={6}
                      textTransform={'capitalize'}
                    >
                      {model}
                    </Heading>
                    {priceFrom ? (
                      <Text
                        color={'white'}
                        className={Styles.horizontalFromText}
                      >
                        From
                      </Text>
                    ) : null}
                  </Box>
                  {priceFrom ? (
                    <Flex
                      gap={{ base: '2' }}
                      marginBottom={6}
                      className={Styles.bannerFeatures}
                    >
                      <Text color={'white'} className={Styles.verticleFromText}>
                        From
                      </Text>
                      <Heading
                        as="h2"
                        className={Styles.titleMd}
                        color={'white'}
                      >
                        {priceFrom !== undefined && (
                          <PriceFormat value={priceFrom} prefix={'R'} />
                        )}
                      </Heading>
                      <Text
                        color={'white'}
                        alignSelf={'flex-end'}
                        marginBottom={1}
                      >
                        Incl VAT
                      </Text>
                    </Flex>
                  ) : null}
                  {banner?.key_features?.length &&
                    align === 'centre_bottom' && (
                      <HStack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        gap={10}
                        marginTop={'auto'}
                        marginBottom={12}
                      >
                        {banner?.key_features &&
                          banner?.key_features.map((feature, index) => (
                            <>
                              <Box
                                key={index}
                                textAlign={'center'}
                                color={'white'}
                              >
                                <Text as="b" fontSize="2xl">
                                  {feature?.text}
                                </Text>
                              </Box>
                              {banner?.key_features &&
                                index !== banner?.key_features.length - 1 && (
                                  <Divider
                                    orientation="vertical"
                                    borderColor={'gray.300'}
                                    h={{ base: '80px', sm: '60px', md: '58px' }}
                                  />
                                )}
                            </>
                          ))}
                      </HStack>
                    )}
                  <Flex gap={4} className={Styles.bannerButtons}>
                    {banner?.show_book_test_drive && newVehicle && (
                      <ButtonCustom
                        btnText="Book a Test Drive"
                        className="btnWhite"
                        moduleClass={Styles.bannerCta}
                        onClick={() =>
                          openLeadForm('test-drive', 'TestDrive', {
                            type: 'new-car',
                            newCar: newVehicle,
                          })
                        }
                      />
                    )}
                    {banner?.show_call_to_action &&
                      banner?.call_to_action_text &&
                      banner?.call_to_action_url && (
                        <LinkCustom
                          href={banner?.call_to_action_url}
                          className="btnOutlineWhite"
                          moduleClass={Styles.bannerCta}
                        >
                          {banner?.call_to_action_text}
                        </LinkCustom>
                      )}
                    {banner?.show_call_to_action_2 &&
                      banner?.call_to_action_text_2 &&
                      banner?.call_to_action_url_2 && (
                        <LinkCustom
                          href={banner?.call_to_action_url_2 ?? '#'}
                          className="btnOutlineWhite"
                          moduleClass={Styles.bannerCta}
                        >
                          {banner?.call_to_action_text_2}
                        </LinkCustom>
                      )}
                  </Flex>
                </Box>
                {video && (
                  <Box className={Styles.videoPlayerController}>
                    <Stack gap={2.5} marginEnd={2}>
                      {playing ? (
                        <Flex
                          alignItems={'center'}
                          onClick={() => videoHandler('pause')}
                          cursor={'pointer'}
                        >
                          <Image
                            alt="play"
                            height={16}
                            width={16}
                            src={PlayIconDisable}
                          />
                          <Image
                            alt="pause"
                            height={16}
                            width={16}
                            src={PauseIcon}
                          />
                          <Text
                            as="b"
                            lineHeight={'1'}
                            mt={0.5}
                            fontSize="xs"
                            color={'white'}
                          >
                            PAUSE
                          </Text>
                        </Flex>
                      ) : (
                        <Flex
                          alignItems={'center'}
                          onClick={() => videoHandler('play')}
                          cursor={'pointer'}
                        >
                          <Image
                            alt="play"
                            height={16}
                            width={16}
                            src={PlayIcon}
                          />
                          <Image
                            alt="pause"
                            height={16}
                            width={16}
                            src={PauseIconDisable}
                          />
                          <Text
                            as="b"
                            lineHeight={'1'}
                            mt={0.5}
                            fontSize="xs"
                            color={'white'}
                          >
                            PLAY
                          </Text>
                        </Flex>
                      )}
                    </Stack>
                  </Box>
                )}
              </Container>
            </Box>
          </Box>
          <Box className={Styles.slideMobile}>
            {banner?.mobile_image || banner?.desktop_image ? (
              <Box position={'relative'}>
                <CustomImage
                  imageKeys={imageKey}
                  imageSrc={
                    getImageLink(
                      banner?.mobile_image ?? banner?.desktop_image,
                    ) ?? ''
                  }
                  alt={`kia-${model}`}
                  width={1440}
                  height={817}
                  loading="eager"
                />
              </Box>
            ) : (
              video && (
                <Box className={Styles.videoPlayer}>
                  <Box className={Styles.videoPlayerController}>
                    <Stack gap={2.5} marginEnd={2}>
                      {playing ? (
                        <Flex
                          alignItems={'center'}
                          onClick={() => videoHandler('pause')}
                          cursor={'pointer'}
                        >
                          <Image
                            alt="play"
                            height={16}
                            width={16}
                            src={PlayIconDisable}
                          />
                          <Image
                            alt="pause"
                            height={16}
                            width={16}
                            src={PauseIcon}
                          />
                          <Text
                            as="b"
                            lineHeight={'1'}
                            mt={0.5}
                            fontSize="xs"
                            color={'white'}
                          >
                            PAUSE
                          </Text>
                        </Flex>
                      ) : (
                        <Flex
                          alignItems={'center'}
                          onClick={() => videoHandler('play')}
                          cursor={'pointer'}
                        >
                          <Image
                            alt="play"
                            height={16}
                            width={16}
                            src={PlayIcon}
                          />
                          <Image
                            alt="pause"
                            height={16}
                            width={16}
                            src={PauseIconDisable}
                          />
                          <Text
                            as="b"
                            lineHeight={'1'}
                            mt={0.5}
                            fontSize="xs"
                            color={'white'}
                          >
                            PLAY
                          </Text>
                        </Flex>
                      )}
                    </Stack>
                  </Box>
                  <video
                    playsInline
                    ref={videoRef}
                    className={Styles.videoPlayerFrame}
                    preload="auto"
                    autoPlay
                    muted
                    loop
                  >
                    <source src={video} type="video/mp4"></source>
                  </video>
                </Box>
              )
            )}
            {banner?.key_features?.length && align === 'centre_bottom' && (
              <Stack
                alignItems={'center'}
                justifyContent={'center'}
                gap={4}
                padding={14}
                backgroundColor={'#f8f8f8'}
              >
                {banner?.key_features &&
                  banner?.key_features.map((feature, index) => (
                    <>
                      <Box key={index} textAlign={'center'} color={'white'}>
                        <Text as="b" fontSize="2xl" color="gray.600">
                          {feature?.text}
                        </Text>
                      </Box>
                      {banner?.key_features &&
                        index !== banner?.key_features.length - 1 && (
                          <Divider
                            orientation="horizontal"
                            borderColor={'gray.600'}
                          />
                        )}
                    </>
                  ))}
              </Stack>
            )}
          </Box>
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default BannerSection;
